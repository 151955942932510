<template>
  <div>
    <div v-show="isReady">
      <cp-investors-head
        :title="$t('holders.title')"
        :country-codes="getCountryCodes"
        :is-omnibus-addable="isOmnibusAddable"
        :actions-type="tabConfig.pageHeaderActionsType"
        :can-create-investor="$can(ActionsTypes.CREATE, AbilitiesTypes.HOLDERS_INVESTOR)"
        :show-import-investors="$can(ActionsTypes.CREATE, AbilitiesTypes.HOLDERS_INVESTOR)"
        :show-import-issuance="false"
        @handleImportInvestors="importInvestors"
        @onAddCustodian="onAddCustodian"
      />
      <cp-tabs
        v-model="selectedTab"
        :tabs="tabs"
        @input="onSelectTab"
      />
      <br>
      <cp-holders-filter
        v-show="tabConfig.showFilter"
        ref="holdersFilter"
        :is-beneficial="isBeneficial"
        @updateFilter="updateFilter"
      />
      <cp-holders-table
        v-if="$refs.holdersFilter && selectedTab"
        :ref="tableRef"
        :selected-tab="selectedTab"
        :table-get-url="tabConfig.tableGetUrl"
        :show-table-header-actions="tabConfig.showTableHeaderActions"
        :filters="filters"
        @onViewElem="toViewPage"
        @onRemoveElem="handlerRemoveInvestor"
        @onExportList="exportInvestorsList"
        @onSort="saveSort"
        @onSelectInvestor="selectInvestor"
        @onEditLabels="editInvestorsLabels"
        @onShowLabels="showFilterLabelsModal"
        @onSelectAll="selectAllInvestors"
        @onMassSelect="handleMassSelect"
        @onUpdateCustodian="onUpdateCustodian"
      />
    </div>
    <div
      v-show="!isReady"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t("holders.loading") }}
      </div>
    </div>
    <cp-labels-modal
      ref="CpLabelsModal"
      :modal-id="modalId"
      :result-filter-labels="resultFilterLabels"
      :predefined-labels="resultFilterPredefinedLabels"
      :selected-invest="isSelectable"
      :error-field="errorField"
      :hide-create-label="hideCreateLabel"
      @onApplyEditLabels="handleEditLabels"
      @onApplyFilterLabels="handleFilterLabels"
      @onHide="errorField = false"
      @onCreateLabel="handleCreateLabel"
      @searchLabel="handleSearchLabel"
      @onDeleteLabel="handleDeleteLabel"
    />
    <add-edite-custodian-modal
      ref="addCustodianModal"
      :view-mode="addEditCustodianModal.viewMode"
      :title="addEditCustodianModal.title"
      :pre-populated-input="addEditCustodianModal.prePopulatedInput"
      @onSaveCustodian="onSaveCustodian"
    />
    <cp-remove-investor
      ref="removeInvestModal"
      :item="deleteItem"
      @deleteIsApproved="deleteIsApproved"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import CpHoldersTable from './components/table';
import CpHoldersFilter from './components/filter';
import CpTabs from '~/components/common/standalone-components/cp-tabs';
import CpLabelsModal from '~/pages/_idIssuer/investors/modals/labelModal';
import CpInvestorsHead from '~/pages/_idIssuer/investors/head-investors';
import CpRemoveInvestor from '~/pages/_idIssuer/investors/remove-investor';
import AddEditeCustodianModal from '@/pages/_idIssuer/investors/modals/add-edit-custodian-modal';
import CpMixPanel, { TrackingTypes } from '../../../../mixins/mix-panel';
import GeneralActionsOfInvestorPages from '~/mixins/general-actions-of-investor-pages';
import routeNames from '~/utilities/routes';
import selectedInvestorMap from './options';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../utilities/ability/abilities';

export default {
  name: 'Holders',
  metaInfo: {
    title: 'Holders',
  },
  components: {
    CpTabs,
    CpHoldersTable,
    CpInvestorsHead,
    CpLabelsModal,
    CpHoldersFilter,
    CpRemoveInvestor,
    AddEditeCustodianModal,
  },
  mixins: [GeneralActionsOfInvestorPages, CpMixPanel],
  data() {
    return {
      selectedTab: '',
      investorsType: {
        issuerId: this.$route.params.idIssuer,
        view: 'holders',
      },
      tableRef: 'holdersTable',
      filterRef: 'holdersFilter',
      custodianModal: '',
      addEditCustodianModal: {
        title: '',
        viewMode: '',
        prePopulatedInput: {},
      },
      currentItems: {},
      tabConfig: {
        showFilter: selectedInvestorMap['holder-of-records'].showFilter,
        tableGetUrl: selectedInvestorMap['holder-of-records'].tableGetUrl,
        showTableHeaderActions:
          selectedInvestorMap['holder-of-records'].showTableHeaderActions,
        pageHeaderActionsType:
          selectedInvestorMap['holder-of-records'].pageHeaderActionsType,
      },
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  computed: {
    ...mapGetters('investors', ['getInvestorById', 'getSorting', 'labelsList']),
    ...mapGetters('issuerList', ['currentIssuer']),
    ...mapGetters('global', ['countries']),
    ...mapGetters('issuersInfo', ['issuerWalletManagers', 'issuerInfo']),
    ...mapGetters('currentOperator', ['authorizationLevel']),
    ...mapGetters('issuersInfo', ['permissionsPerIssuer']),
    ...mapState('predefinedLabels', ['predefinedLabels']),
    isBeneficial() {
      return this.selectedTab === 'beneficial-owners';
    },
    filters() {
      return this.getFilterValues(this.tableFilters);
    },
    isOmnibusAddable() {
      return (
        this.authorizationLevel !== 'basic'
        && this.$route.name === 'holders'
        && this.issuerInfo.allowAddOwo
      );
    },
    tabs() {
      const allowedTabs = [
        {
          text: this.$t('holders.label.tab.holdersOfRecord'),
          value: 'holder-of-records',
        },
      ];
      if (!this.permissionsPerIssuer.isBDRestrictedAccess || this.authorizationLevel === 'brokerdealer') {
        allowedTabs.push({
          text: this.$t('holders.label.tab.beneficialOwners'),
          value: 'beneficial-owners',
        });
      }
      if (
        this.authorizationLevel === 'megaadmin'
        || this.authorizationLevel === 'superadmin'
      ) {
        allowedTabs.push({
          text: this.$t('holders.label.tab.custodianManagement'),
          value: 'custodian-management',
        });
      }
      return allowedTabs;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        const { idIssuer: issuerId, tokenId } = this.$route.params;
        this.getIssuerWalletManagers({ issuerId, tokenId });
      },
    },
  },
  created() {
    this.getSystemPredefinedLabels();
  },
  mounted() {
    this.trackEvent(TrackingTypes.HOLDERS_LAND, {
      issuerId: this.$route.params.idIssuer,
      tokenId: this.$route.params.tokenId,
    });
  },
  methods: {
    ...mapActions('investors', [
      'exportList',
      'getIssuerLabels',
      'labelsBatchOperation',
      'deleteInvestorByUserId',
      'deleteIssuerLabels',
    ]),
    ...mapActions('issuersInfo', ['getIssuerWalletManagers']),
    ...mapActions('custodians', ['addCustodian', 'updateCustodian']),
    ...mapActions('predefinedLabels', ['getSystemPredefinedLabels']),
    ...mapMutations('investors', [
      'SET_INVESTOR_SORTING',
      'SET_LABELS_LIST',
      'CREATE_NEW_LABEL',
    ]),
    ...mapMutations('global', ['SET_FILTER_STATE']),
    toViewPage({ id: idHolder, investorType }, $event) {
      const {
        params: { idIssuer, tokenId },
        query: { search },
      } = this.$route;
      const from = 'holders';
      const routeData = investorType.toLowerCase() === 'omnibus'
        ? this.$router.resolve(routeNames.viewOmnibusDetails(idIssuer, tokenId, idHolder, from, search))
        : this.$router.resolve(routeNames.viewDetails(idIssuer, tokenId, idHolder, from, search));
      if ($event.metaKey) {
        window.open(routeData.href);
      } else {
        this.$router.push(routeData.href);
      }
    },
    onSelectTab(selectedTab) {
      if (selectedTab) {
        this.tabConfig.showTableHeaderActions = selectedInvestorMap[selectedTab].showTableHeaderActions;
        this.tabConfig.showFilter = selectedInvestorMap[selectedTab].showFilter;
        this.tabConfig.tableGetUrl = selectedInvestorMap[selectedTab].tableGetUrl;
        this.tabConfig.pageHeaderActionsType = selectedInvestorMap[selectedTab].pageHeaderActionsType;

        switch (selectedTab) {
          case 'holder-of-records':
            this.trackEvent(TrackingTypes.HOLDERS_HOLDERS_OF_RECORD_LAND, {
              issuerId: this.$route.params.idIssuer,
              tokenId: this.$route.params.tokenId,
            });
            break;
          case 'beneficial-owners':
            this.trackEvent(TrackingTypes.HOLDERS_BENEFICIAL_OWNERS_LAND, {
                issuerId: this.$route.params.idIssuer,
                tokenId: this.$route.params.tokenId,
            });
            break;
          case 'custodian-management':
            this.trackEvent(TrackingTypes.HOLDERS_CUSTODIAN_MANAGER_LAND, {
              issuerId: this.$route.params.idIssuer,
              tokenId: this.$route.params.tokenId,
            });
            break;
          default:
            break;
        }
      }
    },
    getFilterValues(filters) {
      const walletOwner = filters['wallet-owner-name'];
      const { custodian, omnibus } = this.issuerWalletManagers;
      if (!walletOwner) {
        return filters;
      }
      if (custodian && custodian.includes(walletOwner)) {
        return {
          ...filters,
          'wallet-owner-type': 'custodian',
        };
      }
      if (omnibus && omnibus.includes(walletOwner)) {
        return {
          ...filters,
          'wallet-owner-type': 'omnibus',
        };
      }

      return {
        ...filters,
        'wallet-owner-type': 'investor',
        'wallet-owner-name': null,
      };
    },
    custodianActions() {
      return {
        add: this.addCustodian,
        update: this.updateCustodian,
      };
    },
    onAddCustodian() {
      this.addEditCustodianModal.prePopulatedInput = {};
      this.addEditCustodianModal.viewMode = 'add';
      this.addEditCustodianModal.title = this.$t(
        'addEditCustodianModal.titleAdd',
      );
      this.$refs.addCustodianModal.show();
    },
    onUpdateCustodian(items) {
      this.addEditCustodianModal.prePopulatedInput = {
        ...items,
        wallet: items.address,
        logo: items.logo,
      };
      this.addEditCustodianModal.viewMode = 'update';
      this.addEditCustodianModal.title = this.$t(
        'addEditCustodianModal.titleEdit',
      );
      this.$refs.addCustodianModal.show();
    },
    onSaveCustodian(body, modalActionType) {
      const custodianAction = this.custodianActions(body)[modalActionType];
      custodianAction({ issuerId: this.investorsType.issuerId, body })
        .then(() => {
          this.$refs[this.tableRef].updatedTable();
        })
        .finally(() => {
          this.$refs.addCustodianModal.hide();
        });
    },
  },
};
</script>
